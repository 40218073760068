
<template>
  <div class="Menu_I">
    <div class="headNavs">
      <div class="headTilte">菜单</div>
      <div class="headDetails">说明：菜单管理</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>新建菜单</div>
      </div>
      <el-table
        :data="tableData"
        row-key="id"
        class="table ddddd"
        :indent="20"
        style="width: 100%"
        ref="multipleTable"
        @row-click="handleRowClick"
        header-cell-class-name="table-header"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @selection-change="select_change">
        <el-table-column align="left" prop="title" label="菜单名称"></el-table-column>
        <el-table-column align="center" label="图标" >
          <template #default="scope" >
            <img class="Imopp" :src="scope.row.full_icon" alt="">
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="组件" > </el-table-column>
        <el-table-column align="center" prop="status" label="状态" >
          <template #default="scope" >
            <div v-if="scope.row.status == 'normal'" style="color:#b7eb8f">启用</div>
            <div v-if="scope.row.status == 'hidden'" style="color:#ffa39e">停用</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="350">
          <template #default="scope" >
              <div class="el_butto">
                <el-button
                type="text"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
              <div class="el_butto rere">
                  <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.row)"
                  >删除</el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
 

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>菜单名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.title" placeholder="请输入菜单名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>上级菜单：</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
              ref="Casc"
              style="width:100%;"
              v-model="Row_piii"
              :options="BMent"
              :change-on-select="true"
              :props="{ value:'id', label:'title',checkStrictly: true }"
              @change="handleChange"></el-cascader>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>排序：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.weigh" placeholder="请输入排序"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权限标识：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.perssion" placeholder="请输入权限标识"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>路由地址：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.path" placeholder="请输入路由地址"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>组件路径：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.component" placeholder="请输入组件路径"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>后台地址：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请输入后台地址"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName" style="margin-bottom:9px"><span class="signSpan">* </span>是否菜单：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.ismenu" placeholder="请选择是否菜单">
                <el-option v-for="item in Menn" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图标</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :limit="1"
                  :on-success="UP_Success"
                  :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图标</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>状态：</div>
              <div>
                <el-switch
                  v-model="Row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  inactive-color="#dcdfe6">
                </el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>是否外链：</div>
              <div>
                <el-switch
                  v-model="Row.isext"
                  :active-value = 1
                  :inactive-value = 0
                  active-color="#1890FF"
                  inactive-color="#dcdfe6">
                </el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'  

export default {
  name: "basetable",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],
      BMent:[],
      page:{currentPage:1,pageSize:50},
      Menn:[{content:'是',value:1}, {content:'否',value:0}],
      Row:{
        pid: 0, //上级菜单的ID
        title: "", //菜单的标题
        name: "", //后台功能对应的接口地址（不加域名）
        path: "", //地址栏路径，浏览器地址栏 # 号后的路径
        component: "", //页面路径，前端项目src/views后的页面路径
        perssion: "", //权限标识，建议以view、add、edit、delete、export、import、download、upload等关键词结尾
        isext: 0, //是否外部链接，1=是,0=否
        icon: "", //图标
        ismenu: 0, //是否菜单，1=是,0=否
        weigh: '', //权重
        status: "normal", //启用状态，hidden=未启用,normal=已启用
        remark: "" //备注
      },
      total:'',
      titleMath:'',
      editId:'',

      Row_piii:'',
      addPanel:false,
      editPanel:false,
    };
  },
  mounted: function() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.getRule()
  },
  methods: {
    loadTable(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/rule/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize
        },
      }).then(function (res) {
        console.log(res.data.data);
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    showAdd(){
      var that = this
      that.addPanel = true
      that.titleMath = '新增菜单'
    },
    BtnAdd(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/rule/add',
        data: {
          row:that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.loadTable();
        }
      })
    },
    HandleEdit(data){
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑菜单'
      that.editId = data.id
      axios.get('/admin/auth/rule/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row_piii = res.data.data.row.id
          that.Row.pid = res.data.data.row.pid
          that.Row.title = res.data.data.row.title
          that.Row.name = res.data.data.row.name
          that.Row.path = res.data.data.row.path
          that.Row.component = res.data.data.row.component
          that.Row.perssion = res.data.data.row.perssion
          that.Row.isext = res.data.data.row.isext
          that.Row.icon = res.data.data.row.icon
          that.Row.ismenu = res.data.data.row.ismenu
          that.Row.weigh = res.data.data.row.weigh
          that.Row.status = res.data.data.row.status
        }
      })
    },
    BtnEdit(){
      var that = this
      axios({
        method: 'PUT',
        url: '/admin/auth/rule/edit',
        data: {
          id: that.editId,
          row:that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editPanel = false
          that.loadTable();
        }
      })
    },
    HandleDel(data){
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/auth/rule/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
 
    Close(){
      this.editPanel = false
      this.addPanel = false
      this.Row = {}
    },
    getRule(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/rule/index',
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.BMent = res.data.data.rows
          }
      })
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.icon = e.data.url
    },
    handleChange() {
      var that = this
      var num = Number(that.Row_piii.slice(-1))
      that.Row.pid = num
    },

  },

};
</script>


<style scoped>
.Imopp {
  height: 40px;
  width: 40px;
}
.Holiday{
  display: flex;
}
.Holiday div:nth-child(1){
  margin-right: 30px;
}
</style>
